@tailwind base;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Custom tailwindcss preflight
// Delete on removing angular app (everything with #react-app)
#react-app * {
  border-width: 0;
  border-style: solid;
  margin: 0;
  line-height: inherit;
}

#react-app.h-screen, #react-app .h-screen {
  height: 100vh;
}

#react-app .h-content {
  max-height: calc(100vh - 168px);
}

@media only screen and (max-width: 1023px) {
  #react-app.h-screen, #react-app .h-screen {
    height: 100vh;
    height: 100dvh;
  }

  #react-app .h-content {
    max-height: calc(100dvh - 96px);
  }

}

#react-app .markdown pre {
  overflow-y: scroll;
  white-space: initial;
}

#react-app a {
  color: inherit;
  text-decoration: inherit;
}

#react-app button,
#react-app [role='button'] {
  cursor: pointer;
}

#react-app :disabled {
  cursor: not-allowed;
}

#react-app button,
#react-app [type='button'],
#react-app [type='reset'],
#react-app [type='submit'] {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

#react-app h1,
#react-app h2,
#react-app h3,
#react-app h4 {
  font-size: inherit;
  font-weight: inherit;
}

#react-app button,
#react-app input,
#react-app optgroup,
#react-app select,
#react-app textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

#react-app img,
#react-app svg,
#react-app video,
#react-app canvas,
#react-app audio,
#react-app iframe,
#react-app embed,
#react-app object {
  display: block; /* 1 */
  vertical-align: middle; /* 2 */
}

// content viewer audio controls
audio::-webkit-media-controls-panel {
  background-color: #88c425;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiGrid-item, .MuiDialog-root {
  ul li,
  ol li {
    margin-left: 1em;
    padding-left: 1em;
  }

  ul li {
    list-style-type: unset;
    padding-left: .5em;
    margin-left: 1.5em;
  }

  li + li,
  li > ol,
  li > ul,
  ul + ol,
  ul + ul,
  ol + ul,
  ul + ul {
    margin-top: 1em;
  }

  a:not(.md-button) {
    color: var(--color-primary);
    text-decoration: underline;
  }

  a:not(.md-button):hover, a:not(.md-button):focus {
    color: var(--color-primary60);
  }
}

@media only screen and (max-width: 639px) {
  .absolute-center {
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .absolute-center-full {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

// pdf-viewer vertical center
.react-pdf__Page__canvas {
  margin: auto 0 !important;
}

// set loading wrapper to full height so loading spinner is centered
.react-pdf__message--loading {
  height: 100% !important;
}

.markdown > * {
  margin-bottom: 16px !important;
}

.snackbar-container {
  position: absolute;
  top: 105px;
  left: 48%;
  transform: translate3d(-48%, 0, 0);
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  z-index: 10000;

  .MuiPaper-root {
    background: var(--color-white);
    color: var(--color-black);
    box-shadow: 0 0 12px 0 #0000001A;
    border-radius: 8px;
  }

  .toast-error {
    border-left: 4px solid var(--color-error)
  }

  .toast-success {
    border-left: 4px solid var(--color-success)
  }

  .toast-info {
    border-left: 4px solid var(--color-info)
  }
}

.flex > div[id$=rootId] > .layout-fill, // react page wrapper - directly ui-view > root
.flex > div > md-toolbar + div[id$=rootId] > .layout-fill {
  // react page wrapper - nested ui-view > (header) + div > root
  position: absolute;
}

.layout-fill > .flex > .MuiGrid-root {
  height: 100%;
  margin-top: 0; // -16
}

.layout-fill > .flex > .MuiGrid-root > .MuiGrid-item {
  height: 100%;
  padding-top: 0; // 16
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1280px) {
    /* @layout-lg */
    margin-top: 10px;

    .MuiCard-root {
      border-radius: 0;
    }
  }
}

.layout-fill > .flex > .MuiGrid-root > .MuiGrid-item .MuiCard-root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.layout-fill > .flex > .MuiGrid-root > .MuiGrid-item .MuiCardContent-root {
  margin: 0;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.layout-fill > .flex > .MuiGrid-root > .MuiGrid-item .MuiTableContainer-root {
  width: 100%;
  display: flex;
  flex-direction: column;
  //max-height: 100%;
  flex-grow: 1;
  position: relative;
}

.layout-fill > .flex > .MuiGrid-root > .MuiGrid-item .MuiTableContainer-root > table {
  position: absolute;
}

.data-grid-filter {
  padding: 0 16px;
  margin: 4px 0 6px 0;
}

.data-grid-head-sortable > svg,
.data-grid-head-sortable > span,
th svg[data-testid=FilterAltTwoToneIcon],
th svg[data-testid=FilterAltIcon] {
  cursor: pointer;
}

.data-grid-head-sortable > svg:hover,
.data-grid-head-sortable > span:hover,
th svg[data-testid=FilterAltTwoToneIcon]:hover,
th svg[data-testid=FilterAltIcon]:hover {
  opacity: 0.7;
}

@media screen and (max-width: 1536px) {
  .data-grid-show-xl {
    display: none !important;
  }
}

@media screen and (max-width: 1280px) {
  .data-grid-show-lg {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .data-grid-show-md {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .data-grid-show-sm {
    display: none !important;
  }
}

@media screen and (max-width: 640px) {
  // xs
}


.data-grid-zero-data {
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.05);
  height: 100%;
  border-top: 2px solid rgba(0, 0, 0, 0.12);
}

.MuiTableCell-root.data-grid-actions {
  text-align: right;
}

tr.inactive td:not(:last-of-type) {
  color: var(--color-black-inactive);

  svg {
    color: var(--color-black-inactive);
  }
}

@tailwind components;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

// Import fonts from google because our CDN doesn't allow cross origin CORS and it throws an err in console

// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 300;
//   font-display: swap;
//   src: url('../public/fonts/Roboto-LightItalic.ttf') format('truetype');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
//     U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }

// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
//   src: url('../public/fonts/Roboto-Light.ttf') format('truetype');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
//     U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }

// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url('../public/fonts/Roboto-Regular.ttf') format('truetype');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
//     U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }

// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 400;
//   font-display: swap;
//   src: url('../public/fonts/Roboto-Italic.ttf') format('truetype');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
//     U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }

// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
//   src: url('../public/fonts/Roboto-Medium.ttf') format('truetype');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
//     U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }

// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: url('../public/fonts/Roboto-Bold.ttf') format('truetype');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
//     U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }

.glowing-border {
  border-color: #90ee90;
  box-shadow: 0 0 10px #90ee90;
}

.glowing-border-area {
  border-color: rgba(251, 137, 56, 0.537);
  box-shadow: 0 0 10px rgba(251, 137, 56, 0.537);
}

.dragged-item {
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.05), 0px 12px 16px -4px rgba(16, 24, 40, 0.1);
}

#focused .input-label {
  border: 2px solid #fb6825;
}

#focused .radio-field,
#focused .checkbox-field,
#focused .image-field {
  border: 2px solid #fb6825 !important;
  box-shadow: 0px 0px 0px 4px rgba(252, 192, 164, 0.12);
}

.input-readonly .Mui-focused:after {
  display: none;
}

input[type=date][value='']:not(:focus),
input[type='datetime-local'][value='']:not(:focus),
input[type='time'][value='']:not(:focus) {
  color: transparent;
}

input[type=date],
input[type='datetime-local'],
input[type='time'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

input::-webkit-datetime-edit-day-field:focus,
input::-webkit-datetime-edit-month-field:focus,
input::-webkit-datetime-edit-year-field:focus,
input::-webkit-datetime-edit-hour-field:focus,
input::-webkit-datetime-edit-minute-field:focus {
  background-color: var(--color-primary15);
  color: inherit;
}


@media not all and (min-resolution: 0.001dpcm) {
  /* safari */
  body .input-date:focus-within .MuiFormLabel-root:not(.MuiInputLabel-shrink) {
    transform: translate(10px, -10px) !important;
    background: #fff;
    padding: 0 4px;
  }
}

.ql-container.ql-snow {
  .ql-tooltip::before {
    content: "URL: ";
  }

  .ql-tooltip[data-mode=link]::before {
    content: "URL: ";
  }

  .ql-tooltip.ql-editing a.ql-action::after {
    content: "Ok";
  }

  .ql-tooltip a.ql-action::after {
    content: "✎";
  }

  .ql-tooltip a.ql-remove::before {
    content: "✖";
  }

  .ql-editor pre.ql-syntax {
    background: inherit;
    color: inherit;
    padding: 4px 0;
    margin: 0;
  }

}

.ql-toolbar.ql-snow {

  .ql-header[value=""]:before {
    content: "P";
  }

}

.ql-syntax,
.ql-snow .ql-editor pre.ql-syntax {
  white-space: pre-line;
  word-break: break-word;
  background: inherit;
}

@tailwind utilities;
